/* @media (min-width: 768px) { */
  .App {
    text-align: center;
    background-color: #01411C;
    align-items: center;
    color: white;
    width: fit-content;
    height: 100vh;
    margin: 0 auto;
    /* overflow-x: hidden; */
    font-family: sans-serif;
    font-size: large;
    /* overflow-y: scroll; */
    /* overflow-y: auto; */
    /* -webkit-overflow-scrolling: touch; Smooth scrolling on iOS */
  }
/* } */

/* styles for screens smaller than 768px (typical mobile breakpoint) */
/* @media (max-width: 768px) {
  .App {
    text-align: center;
    background-color: black;
    align-items: center;
    color: white;
    width: 500px;
    height: fit-content;
    margin: 0 auto;
    overflow-x: hidden;
  }
} */


/* .body {
  font-family: sans-serif;
  font-size: large;
} */

.list {
  background-color: #01411C;
  height: fit-content;
}

.top {
  text-align: center;
}

input {
  background-color: white;
}

/* .textbox-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.textbox-list li {
  margin: 10px 0;
}

.textbox-list li input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
} */

/* #new-textbox-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 80%;
} */

/* #new-textbox {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
}

#new-textbox:focus {
  outline: none;
  border: 1px solid #007bff;
} */

/* button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

/* Mobile Responsiveness (Media Queries) */
/* @media screen and (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }
  .textbox-list li input */
  /*#new-textbox*/ 
  /* {
    font-size: 0.8rem;
  }
} */

/* input[disabled] {
  background-color: #e0e0e0; Light gray example
} */

/* .input-container {
  position: sticky;
} */

input {
  text-align: center;
}

#searchBox {
  height: fit-content;
  width: fit-content;
  font-size: large;
  margin: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#newBox {
  height: fit-content;
  width: fit-content;
  text-align: center;
  font-size: large;
}

.dropdown {
  display: flex;
  flex-direction: column;
  background-color: white;
}

/* .dropdown:empty {
  border: none;
} */

.dropdown-row {
  cursor: pointer;
  text-align: center;
  margin: 0.5dvh;
  color: #01411C;
  border: #01411C;
}

/* ul {
  list-style-type: none;
  height: fit-content;
  width: fit-content;
  text-align: center;
  list-style-position:inside;
  margin: 0;
  padding: 0;
  justify-content: center;
} */

ul {
  list-style-type: none;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  margin-bottom: 0;
  padding-left: 0;

}

li {
  height: fit-content;
  width: fit-content;
  text-align: center;
  margin: 0;
  padding: 0;
  /* display: inline-block;  Make list items inline-block */
}

textarea {
  height: fit-content;
  width: fit-content;
  resize: none;
}

.ideaListItem {
  background-color: #01411C;
  color: white;
  font-size: large;
  text-align: center;
  border-color: white;
  margin: 10px;
}

/* 
.dropdown-row:selected {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  color: red;
} */

.entity {
  font-size:larger;
}

/* .input-container {
  /* styles for container if needed 
}

.input-container input {
   styles for the input itself 
} */

/* .input-container input.focused {
  position: fixed;
  top: 0;
  left: 0;
  adjust padding/margin as needed
  padding: 10px;
  background-color: #fff;
  z-index: 10; ensure it's above other content
} */

.stayStill {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

button {
  font-size: 15px;
}

.bottom {
  position: absolute;
  top: calc(100% / 6 * 5); /* 1/6th of the image height */
  left: 50%;
  transform: translateX(-50%);
}

.container {
  position: relative;
  width: 100%;
}

*:not(button, .App, .dropdown, .dropdown-row, .ideaListItem, textarea) {
  width: 100%;
}